class Parallax{
  static SM = 576;
  static MD = 768;
  static LG = 992;
  static XL = 1200;
  static repaint = new Set();
  static VH = null;

  static extendTiming(item, ratio){
    item.style.height  = `${ratio * 100}vh`;
    item.style.paddingBottom = `${ratio * 100 - 100}vh`;
  }

  static getVH() {
    if(Parallax.VH === null){
      const elementVH = document.createElement('div')
      elementVH.style.height = "100vh";
      document.body.appendChild(elementVH);
      const bound = elementVH.getBoundingClientRect();
      Parallax.VH = bound.height;
      document.body.removeChild(elementVH);
    }

    return Parallax.VH;
  }

  static itemTransform(item){
    Parallax.repaint.add(item);
    const tx = parseFloat(item.getAttribute('data-tx') || 0);
    const ty = parseFloat(item.getAttribute('data-ty') || 0);
    const tz = parseFloat(item.getAttribute('data-tz') || 0);
    const ro = parseFloat(item.getAttribute('data-ro') || 0);
    item.setAttribute('data-otx', tx);
    item.setAttribute('data-oty', ty);
    item.setAttribute('data-otz', tz);
    item.setAttribute('data-oro', ro);

    const txtTX = (tx === 0) ? 0 : (tx + item.getAttribute('data-tx-unit'));
    const txtTY = (ty === 0) ? 0 : (ty + item.getAttribute('data-ty-unit'));
    const txtTZ = (tz === 0) ? 0 : (tz + item.getAttribute('data-tz-unit'));
    const txtRO = (ro === 0) ? 0 : (ro + 'deg');

    item.style.transform = `translate3d(${txtTX}, ${txtTY}, ${txtTZ}) rotate(${txtRO})`;
  }

  static isMobile(){
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  }

  userScroll = true;
  screenRatio = 0;
  effects = [];

  constructor(opt = {}) {
    this.options = Object.assign({}, opt)

    document.addEventListener('scroll', () => this.onScroll())
    window.addEventListener('resize', ()=> this.onResize());
  }

  static run(element, effects){
    if(!element)return;
    const rect = element.getBoundingClientRect();
    const ratio = ((Parallax.getVH() - rect.y) / Parallax.getVH());

    effects.forEach(effect =>{
      const options = Object.assign({item: '', breakpoint: 0}, effect[1]);
      const item = options.item ? ((typeof(options.item) === 'string') ? element.querySelector(options.item): options.item) : element;
      if(window.innerWidth <= options.breakpoint) return;
      if(!item)return;
      effect[0](item, ratio, effect[1]);
    });

    //clean up transform
    Parallax.repaint.forEach(el => {
      el.removeAttribute('data-tx');
      el.removeAttribute('data-ty');
      el.removeAttribute('data-tz');
      el.removeAttribute('data-tx-unit');
      el.removeAttribute('data-ty-unit');
      el.removeAttribute('data-tz-unit');
      el.removeAttribute('data-ro');
    })

    Parallax.repaint = new Set();
  }

  onResize(){
    Parallax.VH = null;

//    if(Parallax.isMobile())return;
//    this.userScroll = false;
//    window.scrollTo(window.scrollX, this.screenRatio * window.innerHeight);
//    this.userScroll = true;
  }

  onScroll(){
    const sy = window.scrollY;
    if(this.userScroll)this.screenRatio = sy / Parallax.getVH();
    this.effects.forEach(x => Parallax.run(x.element, x.effects));
  }


  static fadeIn(item, ratio, opt={}){
    const options = Object.assign({
      start:0,
      end: 0.5
    }, opt);

    const value = Math.max(0, Math.min(1, ((ratio - options.start) / (options.end - options.start))));
    item.style.opacity = `${value}`;
  }

  static fadeOut(item, ratio, opt={}){
    const options = Object.assign({
      start:1,
      end:2
    }, opt);

    const value = Math.max(0, Math.min(1, 1 - ((ratio - options.start) / (options.end - options.start))));
    item.style.opacity = `${value}`;
  }

  static delay(item, ratio, opt={}){
    const options = Object.assign({
      start: 0.5,
      end: 1,
      distance: 50,
      unit : 'vh',
      direction: 'y'
    }, opt)

    if(typeof(options.distance) === 'function')options.distance = options.distance();

    const min = Math.min(options.start, options.end);
    const max = Math.max(options.start, options.end);
    if(ratio < min) ratio = min;
    if(ratio > max) ratio = max;

    const value = Math.max(0, Math.min(1, 1 - ((ratio - options.start) / (options.end - options.start)))) * options.distance;
    const oldValue = parseFloat(item.getAttribute(`data-ot${options.direction}`));
    if(oldValue === value)return;
    item.setAttribute(`data-t${options.direction}`, value);
    item.setAttribute(`data-t${options.direction}-unit`, options.unit);

    Parallax.itemTransform(item);
  }

  static rotateIn(item, ratio, opt={}){
    const options = Object.assign({
      start: 0.5,
      end: 1,
      rotation: 45,
      offset: 100,
      unit: "%"
    }, opt);

    const min = Math.min(options.start, options.end);
    const max = Math.max(options.start, options.end);
    if(ratio < (min-0.1) || ratio > (max+0.1))return;
    if(ratio < min) ratio = min;
    if(ratio > max) ratio = max;

    const delta = Math.max(0, Math.min(1, 1 - ((ratio - options.start) / (options.end - options.start))));
    item.setAttribute(`data-ty`, delta* options.offset);
    item.setAttribute(`data-ty-unit`, options.unit);
    item.setAttribute(`data-ro`, delta * options.rotation);

    Parallax.itemTransform(item);
  }

  static fixed(item, ratio, opt={}){
    const options = Object.assign({
      start:1,
      end:99999
    }, opt);

    if(ratio > options.start && ratio < options.end){
      item.classList.add('fixed');
    }else{
      item.classList.remove('fixed');
    }

    if(ratio >= options.end){
      item.setAttribute(`data-ty`, (options.end - options.start) * 100);
      item.setAttribute(`data-ty-unit`, 'vh');
    }
  }

  static activate(item, ratio, opt ={}){
    const options = Object.assign({
      start:1,
      end:99999
    }, opt);
    if(options.end === null)options.end = 99999;

    if(ratio < options.start){
      if(item.classList.contains('active'))item.classList.remove('active');
    }else{
      if(!item.classList.contains('active'))item.classList.add('active');
    }

    if(ratio < options.end){
      if(item.classList.contains('active-end'))item.classList.remove('active-end');
    }else{
      if(!item.classList.contains('active-end'))item.classList.add('active-end');
    }
  }

  static scrollspy(item, ratio, opt={}){
    const options = Object.assign({
      start:0.25,
      end:1.25
    }, opt);

    const id = item.getAttribute('id');
    const spy = document.getElementById('scroll-spy');
    if(ratio > options.start && ratio < options.end){
      spy.querySelector(`li[data-target="${id}"]`).classList.add('active');
    }else{
      spy.querySelector(`li[data-target="${id}"]`).classList.remove('active');
    }

  }

  static print(item, ratio, opt={}){
    console.log(ratio , item);
  }

}

